import { instanceApi } from "services/Base/base.instance.services"

export const getWorks=(params)=>{
    const queryString = new URLSearchParams(params).toString();
    return instanceApi().get(`/works?${queryString}`, {
		timeout: 5000, // Sadece bu istek için 5 saniyelik timeout
	});
}
export const searchWorks=(values)=>{
    const queryString = new URLSearchParams(values).toString();
    return instanceApi().get(`/works/search?${queryString}`, {
		timeout: 5000, // Sadece bu istek için 5 saniyelik timeout
	});
}