import axios from 'axios';

export const instanceApi = () => {
	const apiUrl = process.env.REACT_APP_API_URL
		? process.env.REACT_APP_API_URL
		: 'https://api.cizgi.studio/v1/';
	const baseUrl = apiUrl;

	const token = localStorage.token;

	return axios.create({
		baseURL: baseUrl,
		headers: { Authorization: 'Bearer ' + token }
	});
};
