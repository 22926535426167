import { UploadOutlined } from '@ant-design/icons';
import {
	Button,
	Form,
	Image,
	Input,
	InputNumber,
	message,
	notification,
	Select,
	Upload,
} from 'antd';

import { Option } from 'antd/es/mentions';
import { useState } from 'react';
import { instanceApi } from 'services/Base/base.instance.services';
import styled from 'styled-components';
import { waitPlease } from 'utils';
import { device } from '../../../utils/device';

const { TextArea } = Input;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const FormContainer = styled.div`
	width: 50%;

	@media ${device.mobileL} {
		width: 100%;
	}
`;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const ButtonSections = styled.div`
	display: flex;

	@media ${device.mobileL} {
		display: block;
	}
`;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const PrimaryButton = styled.div``;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const DefaultButton = styled.div`
	margin-left: 10px;

	@media ${device.mobileL} {
		margin-left: 0px;
		margin-top: 10px;
	}
`;

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
const CreateNewChatbotForm = () => {
	const [loading, setLoading] = useState(false);
	const [uploadingIcon, setUploadingAvatar] = useState(false);

	const [botImage, setbotImage] = useState(null);
	const [botImageUrl, setbotImageUrl] = useState('');

	const handleUploadIcon = async () => {
		if (!botImage) {
			console.log('Image', botImage);
		}
		const formData = new FormData();
		formData.append('image', botImage);
		setUploadingAvatar(true);

		try {
			const { data } = await instanceApi().post(
				`/upload/image`,
				formData,
				{
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				}
			);
			if (data.url) {
				setbotImageUrl(data.url);
				message.success('Başarıyla yüklendi');
			}
		} catch (error) {
			message.error('Dosya yüklenirken bir hata oluştu.');
		} finally {
			setUploadingAvatar(false);
		}
	};
	const uploadPropsIcon = {
		onRemove: file => {
			setbotImage(null);
		},
		beforeUpload: file => {
			setbotImage(file);
			return false;
		},
		file: botImage,
	};

	const onFinish = values => {
		setLoading(true);

		const data = {
			assistantId: values.assistantId,
			model: values.model,
			name: values.name,
			description: values.description,
			instructions: values.instructions,
			avatar: botImageUrl,
			isActive: true,
			intro: values.intro,
			rank: values.rank ? Number(values.rank) : 0,
		};

		instanceApi()
			.post('/chatbots', data)
			.then(async res => {
				setLoading(false);
				notification.success({
					message: values.name + ' adlı chatbot oluşturuldu.',
				});
				await waitPlease(1000);
				window.location = '/chatbot';
			})
			.catch(err => {
				setLoading(false);
				console.log('error: ', err);
				notification.error({
					message: err.message,
				});
			});
	};

	const onFinishFailed = errorInfo => {
		console.log('Failed:', errorInfo);
		notification.error({
			message: `Lütfen girdiğiniz bilgileri kontrol edip tekrar deneyin!`,
		});
	};

	return (
		<>
			<FormContainer>
				<Form
					layout="vertical"
					name="login-form"
					onFinish={onFinish}
					onFinishFailed={onFinishFailed}>
					<Form.Item label="Bir avatar yükle">
						<p>
							<span
								className="text-dark"
								style={{ marginLeft: 10 }}>
								<div>
									<Upload
										accept="image/png,image/jpeg,image/jpg"
										onChange={() => {
											handleUploadIcon();
										}}
										showUploadfalse={false}
										{...uploadPropsIcon}>
										<Button icon={<UploadOutlined />}>
											Resim Seç
										</Button>
									</Upload>
									<div style={{ marginTop: '1rem' }}>
										{(botImageUrl || botImage) && (
											<Image
												width={100}
												style={{
													borderRadius: 100,
													width: 80,
													height: 80,
													objectFit: 'cover',
												}}
												src={
													botImageUrl ||
													URL.createObjectURL(
														botImage
													)
												}
											/>
										)}
									</div>
									{/* {!!botImage && (
										<Button
											type="primary"
											onClick={handleUploadIcon}
											loading={uploadingIcon}
											style={{ marginTop: 16 }}>
											{uploadingIcon
												? 'Yükleniyor...'
												: 'Yükle'}
										</Button>
									)} */}
								</div>
							</span>
						</p>
					</Form.Item>
					<a
						href="https://platform.openai.com/assistants"
						target="_blank"
						rel="noreferrer">
						OpenAI Assistants Sayfası
					</a>
					<Form.Item
						name="assistantId"
						label="Assistan Id"
						rules={[
							{
								required: true,
								message: 'Assistan Id zorunlu alandır.',
							},
						]}>
						<Input />
					</Form.Item>
					<Form.Item
						name="model"
						label="Bot modeli"
						rules={[
							{
								required: true,
								message: 'Lütfen model seçin.',
							},
						]}>
						<Select>
							<Option value={'gpt-3.5-turbo'}>
								gpt-3.5-turbo
							</Option>
							<Option value={'gpt-4'}>gpt-4</Option>
						</Select>
					</Form.Item>
					<Form.Item
						name="name"
						label="Bota bir isim verin."
						rules={[
							{
								required: true,
								message: 'Lütfen bot adini girin.',
							},
						]}>
						<Input />
					</Form.Item>
					<Form.Item
						name="instructions"
						label="Bot icin prompt yazin."
						rules={[
							{
								required: true,
								message: 'Prompt zorunlu alan',
							},
						]}>
						<Input />
					</Form.Item>

					<Form.Item
						name="description"
						label="Bot icin bir aciklama girin">
						<TextArea />
					</Form.Item>

					<Form.Item
						name="intro"
						label="Mesajlasma başlangıcında gösterilecek metin">
						<TextArea />
					</Form.Item>
					<Form.Item
						name="rank"
						label="Sıralama Önceliği (Ne kadar yüksekse o kadar önce görünür)">
						<InputNumber min={0} />
					</Form.Item>

					<Form.Item>
						<ButtonSections>
							<PrimaryButton>
								<Button
									style={{ width: '180px' }}
									type="primary"
									htmlType="submit"
									loading={loading}>
									Oluştur
								</Button>
							</PrimaryButton>
							<DefaultButton>
								<Button
									style={{ width: '180px' }}
									type="default"
									href={'/chatbot'}
									htmlType="submit">
									Geri
								</Button>
							</DefaultButton>
						</ButtonSections>
					</Form.Item>
				</Form>
			</FormContainer>
		</>
	);
};
export default CreateNewChatbotForm;
