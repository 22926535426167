import React, { useState } from 'react';
import { Form, Input, Button, Card, message } from 'antd';
import { instanceApi } from '../../../services/Base/base.instance.services';
import styled from 'styled-components';

const Container = styled.div`
	max-width: 600px;
	margin: 0 auto;
`;

const Title = styled.h1`
	font-size: 24px;
	margin-bottom: 20px;
`;

const CreateEpisodeFromDriveForm = () => {
	const [loading, setLoading] = useState(false);
	const [form] = Form.useForm();

	const onFinish = async values => {
		setLoading(true);
		try {
			await instanceApi().post(
				'/admin/create-episode-from-google-drive-images',
				{
					workId: values.workId,
					googleDriveLink: values.driveLink,
				},
				{
					timeout: 1200000,
				}
			);
			message.success('Bölüm başarıyla oluşturuldu');
			form.resetFields();
		} catch (error) {
			if (error.code === 'ECONNABORTED') {
				message.error(
					'İşlem zaman aşımına uğradı. Lütfen tekrar deneyin.'
				);
			} else {
				message.error(
					error.response?.data?.message || 'Bir hata oluştu'
				);
			}
		} finally {
			setLoading(false);
		}
	};

	return (
		<Container>
			<Title>Çizgi Roman Oluştur</Title>
			<Card>
				<Form form={form} layout="vertical" onFinish={onFinish}>
					<Form.Item
						label="Eser ID"
						name="workId"
						rules={[
							{
								required: true,
								message: 'Lütfen eser ID giriniz',
							},
						]}>
						<Input placeholder="Eser ID" />
					</Form.Item>

					<Form.Item
						label="Google Drive Klasör Linki"
						name="driveLink"
						rules={[
							{
								required: true,
								message: 'Lütfen Drive linkini giriniz',
							},
							{
								pattern: /^https:\/\/drive\.google\.com\/.*$/,
								message:
									'Geçerli bir Google Drive linki giriniz',
							},
						]}>
						<Input placeholder="https://drive.google.com/..." />
					</Form.Item>

					<Form.Item>
						<Button
							type="primary"
							htmlType="submit"
							loading={loading}
							block>
							{loading ? 'İşleniyor...' : 'Oluştur'}
						</Button>
					</Form.Item>
				</Form>
			</Card>
		</Container>
	);
};

export default CreateEpisodeFromDriveForm;
