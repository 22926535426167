import { getWorks, searchWorks } from "services/works/workServices";
import { useInfiniteQuery } from "@tanstack/react-query";
import { result } from "lodash";
import { useMemo } from "react";

export const WorkLisQueriest = (page , pageSize, filters,sortBy = {},searchValue) => {
  const newFilters=Object?.fromEntries(
    Object.entries(filters)?.filter(([_, value]) => value !== null && value !== undefined && value !== "")
  );
 
  const query = useInfiniteQuery({
    queryKey: ['work-list',page,pageSize,sortBy,filters],
    queryFn: ({ pageParam = page }) =>
    searchValue ?
    searchWorks({ query:searchValue,page: pageParam}):
    getWorks({ page: pageParam, limit: pageSize,...newFilters,...sortBy}),
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      const { page, totalPages } = lastPage.data;
      return page < totalPages ? page + 1 : undefined; 
    },
    getPreviousPageParam: (firstPage) => {
      const { page } = firstPage.data;
      return page > 1 ? page - 1 : undefined; 
    },
    retry: 3, 
    retryDelay: 500, 
  });

  const currentPage = useMemo(() => {
    return query?.data?.pages?.[query?.data?.pages.length - 1]?.data?.page;
  }, [query?.data]);

  const listData = useMemo(() => {
    return query?.data?.pages?.[query?.data?.pages.length - 1]?.data?.results || [];
  }, [query?.data]);

  const totalResults = useMemo(() => {
    return result(query, 'data.pages[0].data.totalResults', 0);
  }, [query?.data]);
  return {
    ...query,
    data: listData,
    totalResults,
    currentPage
  };
};

