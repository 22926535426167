import { result } from 'lodash';

export function unescapeHtml(htmlStr) {
	if (!htmlStr) return '';
	htmlStr = htmlStr.replace(/&lt;/g, '<');
	htmlStr = htmlStr.replace(/&gt;/g, '>');
	htmlStr = htmlStr.replace(/&quot;/g, '"');
	htmlStr = htmlStr.replace(/&#39;/g, "'");
	htmlStr = htmlStr.replace(/&amp;/g, '&');
	return htmlStr;
}

export const getStrVal = (obj, path, lang = 'tr', defaultValue = '') => {
	const steps = [
		() => result(obj, path),
		() => result(obj, `${path}.${lang}`),
		() => result(obj, `${path}.${'tr'}`),
	];

	const val = steps.reduce((acc, step) => {
		if (typeof acc === 'string') return acc;
		const val = step();
		return val;
	}, null);
	return val || defaultValue;
};
