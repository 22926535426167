import React from "react";
import "react-datepicker/dist/react-datepicker.css";

const Filter = ({ columns, filters, onFilterChange }) => {
  return (
    <div style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>
      <div
        style={{
          display: "flex",
          gap: "10px",
          flexWrap: "wrap",
          marginBottom: "20px",
        }}
      >
        {columns.map((column) => {
            return (
              <div key={column.id} style={{ display: "flex", flexDirection: "column" }}>
                <label htmlFor={column.id}>{column.header}</label>
                  <select
                  id={column?.id}
                  value={
                    column?.data?.find((item) => item?.id === filters[column?.id])?.title || ""
                  }
                  onChange={(e) => onFilterChange(column.id, e.target.value)}
                    style={{
                      padding: "8px",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      width: "200px",
                    }}
                  >
                    <option value="">Tüm Türler</option>
                    {column.data?.map((item) => (
                      <option key={item.id} value={item.value}>
                        {item.title}
                      </option>
                    ))}
                  </select>
              </div>
            );
        })}
      </div>
    </div>
  );
};

export default Filter;
