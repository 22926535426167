import CreateEpisodeFromDriveForm from 'components/organisms/Episodes/CreateEpisodeFromDriveForm';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import HorizontalMenu from '../../components/organisms/HorizontalMenu';
import SideMenu from '../../components/organisms/SideMenu';
import TopMenu from '../../components/organisms/TopMenu';
import { device } from '../../utils/device';

const Container = styled.div`
	width: 100%;
	display: flex;
`;

const TopSection = styled.div``;

const SideMenuSection = styled.div`
	position: fixed;

	@media ${device.ipadL} {
		display: none;
	}
`;

const MobileMenu = styled.div`
	display: none;
	width: 100%;

	@media ${device.ipadL} {
		display: block;
	}
`;

const CreateEpisodeFromDrivePage = () => {
	const [isToogleActive, setIsToogleActive] = useState(false);

	if (isToogleActive) {
		var marginLeft = 90;
	} else {
		var marginLeft = 270;
	}

	const Content = styled.div`
		width: 100%;
		margin-top: 100px;
		margin-left: ${marginLeft}px;
		margin-right: 30px;

		@media ${device.ipadL} {
			margin-left: 20px;
		}
	`;

	return (
		<>
			<Helmet>
				<title>Çizgi App - Drive'dan Bölüm Oluştur</title>
			</Helmet>
			<TopSection>
				<TopMenu setIsToogleActive={setIsToogleActive} />
			</TopSection>
			<MobileMenu>
				<HorizontalMenu isToogleActive={isToogleActive} />
			</MobileMenu>
			<Container>
				<SideMenuSection>
					<SideMenu
						sideNavTheme={'SIDE_NAV_LIGHT'}
						isToogleActive={isToogleActive}
					/>
				</SideMenuSection>
				<Content>
					<CreateEpisodeFromDriveForm />
				</Content>
			</Container>
		</>
	);
};

export default CreateEpisodeFromDrivePage;
