import { useEffect } from 'react';
import { ThemeSwitcherProvider } from 'react-css-theme-switcher';
import { useTranslation } from 'react-i18next';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { THEME_CONFIG } from './configs/AppConfig';
import { withTrans } from './i18n/withTrans';
import LoginPage from './pages/Auth/LoginPage';
import { AvatarPage, CreateAvatarPage, EditAvatar } from './pages/Avatar';
import {
	CategoryPage,
	CreateCategoryPage,
	EditCategoryPage,
} from './pages/Category';
import DashboardPage from './pages/Dashboard/DashboardPage';
import CreateEpisodeFromDrivePage from './pages/Episodes/CreateEpisodeFromDrivePage';
import {
	CreatePrivacyPolicyPage,
	EditPrivacyPolicyPage,
	PrivacyPolicyPage,
} from './pages/PrivacyPolicy';
import { EditSettingPage, SettingPage } from './pages/Settings';
import {
	ActiveSliderPage,
	CreateSliderPage,
	EditSliderPage,
	SliderPage,
} from './pages/Slider';
import {
	CreateUserPage,
	EditUserPage,
	SystemUserPage,
	UserPage,
} from './pages/User';
import { ProtectedRoute } from './protected/protected.route';

import Route404 from 'pages/404Route/404Route';
import { ChatBot } from 'pages/ChatBot';
import CreateChatBotPage from 'pages/ChatBot/CreateChatBotPage';
import EditChatBotPage from 'pages/ChatBot/EditChatBotPage';
import { ModerationPostPage } from 'pages/ModerationReports';
import { MonetizationPage } from 'pages/Monetization';
import { ShowcaseRequestsPage } from 'pages/ShowcaseRequests';
import { SocialPostsPage } from 'pages/SocialPosts';
import {
	AddWorksToHomePage,
	HomeListEditPage,
	HomeListPage,
} from './pages/HomeList';
import { ReportsPage } from './pages/Reports';
import { WorkPage } from './pages/Work';
import EpisodeDetailPage from './pages/Work/EpisodeDetailPage';
import WorkDetailPage from './pages/Work/WorkDetailPage';
import {
	QueryClient,
	QueryClientProvider,
	useQuery,
} from '@tanstack/react-query';
const queryClient = new QueryClient();

const themes = {
	dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
	light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

function App() {
	const { i18n } = useTranslation();

	useEffect(() => {
		if (localStorage.getItem('language') === 'en') {
			i18n.changeLanguage('en');
		} else {
			i18n.changeLanguage('de');
		}
	}, []);

	return (
		<>
			<QueryClientProvider client={queryClient}>
				<Router>
					<ThemeSwitcherProvider
						themeMap={themes}
						defaultTheme={THEME_CONFIG.currentTheme}
						insertionPoint="styles-insertion-point">
						<Switch>
							<Route exact path={'/'} component={LoginPage} />

							<ProtectedRoute
								exact
								path={'/dashboard'}
								component={DashboardPage}
							/>
							<ProtectedRoute
								exact
								path={'/monetizations'}
								component={MonetizationPage}
							/>

							<ProtectedRoute
								exact
								path={'/moderation'}
								component={ModerationPostPage}
							/>

							<Route exact path={'/users'} component={UserPage} />
							<Route
								exact
								path={'/showcase-requests'}
								component={ShowcaseRequestsPage}
							/>
							<Route
								exact
								path={'/social-posts'}
								component={SocialPostsPage}
							/>

							<Route
								exact
								path={'/create/new/user'}
								component={CreateUserPage}
							/>

							<Route
								exact
								path={'/user/edit/:id'}
								component={EditUserPage}
							/>

							<Route
								exact
								path={'/system-users'}
								component={SystemUserPage}
							/>

							<Route
								exact
								path={'/avatars'}
								component={AvatarPage}
							/>

							<Route
								exact
								path={'/avatar/edit/:id'}
								component={EditAvatar}
							/>

							<Route
								exact
								path={'/create/new/avatar'}
								component={CreateAvatarPage}
							/>

							<Route
								exact
								path={'/chatbot'}
								component={ChatBot}
							/>
							<Route
								exact
								path={'/create/chatbot'}
								component={CreateChatBotPage}
							/>
							<Route
								exact
								path={'/edit/chatbot/:id'}
								component={EditChatBotPage}
							/>
							<Route
								exact
								path={'/settings'}
								component={SettingPage}
							/>

							<Route
								exact
								path={'/edit/user/profile'}
								component={EditSettingPage}
							/>

							<Route
								exact
								path={'/sliders'}
								component={SliderPage}
							/>

							<Route
								exact
								path={'/policies'}
								component={PrivacyPolicyPage}
							/>

							<Route
								exact
								path={'/policy/edit/:id'}
								component={EditPrivacyPolicyPage}
							/>

							<Route
								exact
								path={'/create/new/policy'}
								component={CreatePrivacyPolicyPage}
							/>

							<Route
								exact
								path={'/categories'}
								component={CategoryPage}
							/>

							<Route
								exact
								path={'/category/edit/:id'}
								component={EditCategoryPage}
							/>

							<Route
								exact
								path={'/create/new/category'}
								component={CreateCategoryPage}
							/>

							<Route
								exact
								path={'/active-sliders'}
								component={ActiveSliderPage}
							/>

							<Route
								exact
								path={'/create/new/slider'}
								component={CreateSliderPage}
							/>

							<Route
								exact
								path={'/slider/edit/:id'}
								component={EditSliderPage}
							/>

							<Route exact path={'/works'} component={WorkPage} />

							<Route
								exact
								path={'/works/:id'}
								component={WorkDetailPage}
							/>

							<Route
								exact
								path={'/works/:workId/episodes/:episodeId'}
								component={EpisodeDetailPage}
							/>

							<Route
								exact
								path={'/home-lists'}
								component={HomeListPage}
							/>

							<Route
								exact
								path={'/home-lists/:id'}
								component={HomeListEditPage}
							/>

							<Route
								exact
								path={'/create/work/:id'}
								component={AddWorksToHomePage}
							/>

							<Route
								exact
								path={'/reports'}
								component={ReportsPage}
							/>

							<Route
								exact
								path={'/episodes/create-from-drive'}
								component={CreateEpisodeFromDrivePage}
							/>

							<Route path="*" component={Route404} />
						</Switch>
					</ThemeSwitcherProvider>
				</Router>
			</QueryClientProvider>
		</>
	);
}

export default withTrans(App);
