import React from 'react';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {
  Table,
  Th,
  Thead,
  Tr,
  Tbody,
  Td,
  Box,
  Spinner,
  Text,
} from '@chakra-ui/react';
import {
	Pagination,
  Checkbox
} from 'antd';
import styled from 'styled-components';

const SortIcon = styled.span`
  font-size: 18px;
  font-weight: bold;
  transition: transform 0.2s ease;
  &.desc {
    color: #007bff; 
  }
  &.asc {
    color: #28a745; 
  }

  &:hover {
    transform: scale(1.2);
  }
`;

const DataTable = ({
  data,
  totalResults,
  fetchNextPage,
  fetchPreviousPage,
  currentPage,
  isFetching,
  columns,
  shadow = true,
  paginationOnChange,
  setShowSizeChange,
  defaultPageSize,
  sortByHeader,
  onSortChange,
  rowSelection,
  selectedRows
}) => {

  const table = useReactTable({
    data: data ?? [],
    columns,
    pageCount: totalResults ?? -1,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
  });

  const toggleSort = (header) => {
    let newSortBy = [...(sortByHeader?.sortBy || [])]; 
  
    const existingIndex = newSortBy.findIndex((sort) =>
      sort.startsWith(`${header}:`)
    );
  
    if (existingIndex !== -1) {
      const currentSort = newSortBy[existingIndex];
      if (currentSort === `${header}:desc`) {
        newSortBy.splice(existingIndex, 1); 
      } else if (currentSort === `${header}:asc`) {
        newSortBy[existingIndex] = `${header}:desc`;
      }
    } else {
      newSortBy.push(`${header}:asc`);
    }
    if (onSortChange) {
      onSortChange(newSortBy);
    }
  };
  

  const getSortIcon = (header) => {
    const sortState = sortByHeader?.sortBy?.find((sort) =>
      sort.startsWith(`${header}:`)
    );
  
    if (sortState === `${header}:desc`) {
      return <SortIcon className="desc">↓</SortIcon>; 
    } else if (sortState === `${header}:asc`) {
      return <SortIcon className="asc">↑</SortIcon>; 
    }
  
    return null;
  };

  const handleSelectAll = () => {
    if(selectedRows?.length === data?.length)
    {
      rowSelection([])
    }
    else{
      const allRowIds = data?.map((row) => row?.id); 
      rowSelection(allRowIds)
    }
   
  };
 
  return (
    <Box
      overflow={'hidden'}
      borderRadius={'md'}
      display={'flex'}
      flexDirection={'column'}
      boxShadow={shadow ? 'md' : undefined}
      p={4}
      bg="white">
      {isFetching ? (
        <Box display="flex" justifyContent="center" alignItems="center" p={8}>
          <Spinner size="xl" color="blue.500" />
          <Text ml={4} fontSize="lg" color="gray.600">
            Yükleniyor...
          </Text>
        </Box>
      ) : (
        <>
          <Table variant="simple">
            <Thead bg="gray.100">
              <Tr>
                <Th
                 width="50px">
                  <Checkbox
                    type="checkbox"
                    checked={selectedRows?.length === data?.length}
                    onChange={handleSelectAll}
                  />
                </Th>
                {table?.getHeaderGroups()?.map((headerGroup) =>
                  headerGroup.headers.map((header) => (
                 
                     <Th
                      key={header?.id}
                      cursor={'pointer'}
                      onClick={() => toggleSort(header.column.id)}
                      _hover={{ bg: 'gray.200' }}
                      fontWeight="bold"
                      verticalAlign="top"
                      color="gray.700"
                      width="100px"
                      justifyContent="space-between" 
                      textTransform="uppercase">
                      {header.isPlaceholder
                        ? null
                        : flexRender(header.column.columnDef.header, header.getContext())}
                      <span>{getSortIcon(header.column.id)}</span>
                    </Th>
               
                  ))
                )}
              </Tr>
            </Thead>
            <Tbody>
              {table.getRowModel().rows.map((row, index) => (
                <Tr
                  key={row.id} 
                  verticalAlign="middle"
                  height="80px"
                  borderBottom={index !== table.getRowModel().rows.length - 1 ? '0.5px solid gray' : 'none'}>
                  <Td>
                    <Checkbox
                      id={row.id}
                      type="checkbox"
                      checked={selectedRows?.some(id => id === row?.original?.id)}
                      onChange={() => rowSelection(row.original.id)}
                    />
                  </Td>
                  {row.getVisibleCells().map((cell) => (
                    <Td key={cell.id} verticalAlign="middle" p={2}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </Td>
                  ))}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </>
      )}
      <Pagination
        style={{ padding: '15px' }}
        defaultCurrent={currentPage}
        defaultPageSize={defaultPageSize}
        current={currentPage}
        total={totalResults}
        onChange={paginationOnChange}
        onShowSizeChange={setShowSizeChange}
      />
    </Box>
  );
};

export default DataTable;
