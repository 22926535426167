import { UploadOutlined } from '@ant-design/icons';
import {
	Button,
	Form,
	Image,
	Input,
	InputNumber,
	message,
	notification,
	Select,
	Upload,
} from 'antd';

import { Option } from 'antd/es/mentions';
import { useEffect, useState } from 'react';
import { instanceApi } from 'services/Base/base.instance.services';
import styled from 'styled-components';
import { device } from '../../../utils/device';
import { getStrVal } from 'utils/string';
const { TextArea } = Input;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const FormContainer = styled.div`
	width: 50%;

	@media ${device.mobileL} {
		width: 100%;
	}
`;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const ButtonSections = styled.div`
	display: flex;

	@media ${device.mobileL} {
		display: block;
	}
`;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const PrimaryButton = styled.div``;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const DefaultButton = styled.div`
	margin-left: 10px;

	@media ${device.mobileL} {
		margin-left: 0px;
		margin-top: 10px;
	}
`;

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
const EditChatbotForm = ({ id }) => {
	const [loading, setLoading] = useState(false);
	const [uploadingIcon, setUploadingAvatar] = useState(false);

	const [botImage, setbotImage] = useState(null);
	const [botImageUrl, setbotImageUrl] = useState('');
	const [getDetails, setDetails] = useState();
	const statusLookup = {
		Aktif: true,
		Pasif: false,
	};

	//Get details
	useEffect(() => {
		const getChatbotDetailsFromServer = async () => {
			const resp = await instanceApi().get(`/chatbots/${id}`);

			setDetails(resp?.data);
			setbotImageUrl(resp?.data.avatar);
		};

		getChatbotDetailsFromServer();
	}, [id]);

	//upload image
	const handleUploadIcon = async () => {
		if (!botImage) {
			console.log('Image', botImage);
		}
		const formData = new FormData();
		formData.append('image', botImage);
		setUploadingAvatar(true);

		try {
			const { data } = await instanceApi().post(
				`/upload/image`,
				formData,
				{
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				}
			);
			if (data.url) {
				setbotImageUrl(data.url);
				message.success('Başarıyla yüklendi');
			}
		} catch (error) {
			message.error('Dosya yüklenirken bir hata oluştu.');
		} finally {
			setUploadingAvatar(false);
		}
	};

	//Set the image
	const uploadPropsIcon = {
		onRemove: file => {
			setbotImage(null);
		},
		beforeUpload: file => {
			setbotImage(file);
			return false;
		},
		file: botImage,
	};

	//On submited
	const onFinish = values => {
		setLoading(true);

		const data = {
			assistantId: values.assistantId || getDetails.assistantId,
			model: values.model,
			name: values.name,
			description: values.description,
			instructions: values.instructions,
			avatar: botImageUrl,
			isActive: statusLookup[values.isActive],
			intro: values.intro,
			rank: values.rank ? Number(values.rank) : getDetails.rank ?? 0,
		};

		instanceApi()
			.patch('/chatbots/' + id, data)
			.then(async res => {
				setLoading(false);
				notification.success({
					message: 'Chatbot güncellendi.',
				});
			})
			.catch(err => {
				setLoading(false);
				console.log('error: ', err);
				notification.error({
					message: err.message,
				});
			});
	};

	const onFinishFailed = errorInfo => {
		console.log('Failed:', errorInfo);
		notification.error({
			message: `Lütfen girdiğiniz bilgileri kontrol edip tekrar deneyin!`,
		});
	};

	if (!getDetails)
		return <>Detaylar alinirken, biraz beklemeniz gerekiyor...</>;
	return (
		<>
			<FormContainer>
				<Form
					layout="vertical"
					name="login-form"
					onFinish={onFinish}
					onFinishFailed={onFinishFailed}>
					<Form.Item label="Bir avatar yukle">
						<p>
							<span
								className="text-dark"
								style={{ marginLeft: 10 }}>
								<div>
									<Upload
										accept="image/png,image/jpeg,image/jpg"
										onChange={() => {
											handleUploadIcon();
										}}
										showUploadfalse={false}
										{...uploadPropsIcon}>
										<Button icon={<UploadOutlined />}>
											Resim Seç
										</Button>
									</Upload>
									<div style={{ marginTop: '1rem' }}>
										{(botImageUrl || botImage) && (
											<Image
												width={100}
												style={{
													borderRadius: 100,
													width: 80,
													height: 80,
													objectFit: 'cover',
												}}
												src={
													botImageUrl ||
													URL.createObjectURL(
														botImage
													)
												}
											/>
										)}
									</div>
								</div>
							</span>
						</p>
					</Form.Item>
					<a
						href="https://platform.openai.com/assistants"
						target="_blank"
						rel="noreferrer">
						OpenAI Assistants Sayfası
					</a>
					<Form.Item
						name="assistantId"
						label="Assistan Id (OpenAI platformundan alınan id)"
						rules={[
							{
								message: 'Asistan Id zorunlu alandir.',
							},
						]}>
						<Input defaultValue={getDetails?.assistantId} />
					</Form.Item>
					<Form.Item
						name="model"
						label="Bot modeli"
						rules={[
							{
								message: 'Lütfen model Seçin.',
							},
						]}>
						<Select defaultValue={getDetails?.model}>
							<Option value={'gpt-3.5-turbo'}>
								gpt-3.5-turbo
							</Option>
							<Option value={'gpt-4'}>gpt-4</Option>
						</Select>
					</Form.Item>
					<Form.Item
						name="name"
						label="Bota bir isim verin."
						rules={[
							{
								message: 'Lütfen bot adini girin.',
							},
						]}>
						<Input defaultValue={getDetails?.name.tr} />
					</Form.Item>
					<Form.Item
						name="instructions"
						label="Bot icin prompt yazin."
						rules={[
							{
								message: 'Prompt zorunlu alan',
							},
						]}>
						<Input defaultValue={getDetails.instructions} />
					</Form.Item>

					<Form.Item
						name="isActive"
						label="Model kullanicim durumu"
						rules={[
							{
								message: 'Model kullanim durumunu belirleyin',
							},
						]}>
						<Select
							defaultValue={
								getDetails?.isActive ? 'Aktif' : 'Pasif'
							}>
							<Option value={'Aktif'}>Aktif</Option>
							<Option value={'Pasif'}>Pasif</Option>
						</Select>
					</Form.Item>
					<Form.Item
						name="description"
						label="Bot icin bir aciklama girin">
						<TextArea defaultValue={getDetails.description.tr} />
					</Form.Item>
					<Form.Item
						name="intro"
						label="Mesajlasma başlangıcında gösterilecek metin">
						<TextArea
							defaultValue={getStrVal(getDetails, 'intro', 'tr')}
						/>
					</Form.Item>
					<Form.Item
						name="rank"
						label="Sıralama Önceliği (Ne kadar yüksekse o kadar önce görünür)">
						<InputNumber
							min={0}
							defaultValue={Number(getDetails.rank ?? 0)}
						/>
					</Form.Item>

					<Form.Item>
						<ButtonSections>
							<PrimaryButton>
								<Button
									style={{ width: '180px' }}
									type="primary"
									htmlType="submit"
									loading={loading}>
									Kaydet
								</Button>
							</PrimaryButton>
							<DefaultButton>
								<Button
									style={{ width: '180px' }}
									type="default"
									href={'/chatbot'}
									htmlType="submit">
									Geri
								</Button>
							</DefaultButton>
						</ButtonSections>
					</Form.Item>
				</Form>
			</FormContainer>
		</>
	);
};
export default EditChatbotForm;
